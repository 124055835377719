<template>
  <transition v-show="showImgEdge" name="modal">
    <div class="modal-mask">
      <div class="modal-dialog modal-xl " role="document">
        <div class="modal-content">
          <div class="modal-body">
            <img id="img" ref="img" class="invisible" src=""
              style="overflow:hidden; background-size:contain; position:fixed" />
            <div class="div-image">
              <canvas id="canvas" ref="canvas" class="imgcanvas rounded-5" style="background-size:contain"></canvas>
              <button class="btn w-64 btn-slice" type="button" @click="showSlice = true">
                <svg width="35" height="39" viewBox="0 0 35 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.265 38.2356L28.7441 9.89941L0.407937 7.42032" stroke="white" stroke-width="3"/>
                <path d="M8.92441 0.274026L6.44531 28.6102L34.7815 31.0893" stroke="white" stroke-width="3"/>
                </svg>
              </button>

              <div class="div-action">
                <button class="btn w-64" type="button" @click="makeEdge()">
                  <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.0035 3L38 18L23.0035 33M2 18L36.875 18" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                  </svg>
                </button>

                <button class="btn w-64" type="button" @click="reset()">
                  <svg width="39" height="41" viewBox="0 0 39 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.8509 19.8215L3.10087 19.8215L3.10087 19.5715C3.10087 10.4917 10.4582 3.10732 19.5005 3.10732C28.5428 3.10732 35.9001 10.4917 35.9001 19.5715C35.9001 28.6512 28.5428 36.0357 19.5005 36.0357C14.8655 36.0357 10.6841 34.0823 7.69975 30.9643L15.8005 30.9643L16.0505 30.9643L16.0505 30.7143L16.0505 28.857L16.0505 28.607L15.8005 28.607L4.70051 28.607L4.45051 28.607L4.45051 28.857L4.45051 40L4.45051 40.25L4.70051 40.25L6.55036 40.25L6.80036 40.25L6.80036 40L6.80036 33.3643C10.1372 36.4738 14.5849 38.3927 19.5 38.3927C29.8567 38.3927 38.25 29.9654 38.25 19.5717C38.25 9.17798 29.8567 0.750001 19.5 0.750002C9.14332 0.750003 0.749997 9.17725 0.749998 19.5717L0.749998 19.8217L1.00003 19.8217L2.8509 19.8215Z" fill="white" stroke="white" stroke-width="0.5"/>
                  </svg>
                </button>
              </div>
              <canvas id="dstimg" ref="dstimg" class="imgcanvas rounded-5"></canvas>
            </div>
          </div>

          <div class="div-range">
            <div class="slider">
              <label class="range-label-left" for="customRange1">{{$t('최솟값')}}</label>
              <input id="customRange1" v-model="sliderValue1" class="form-range" max="200"
                min="0" type="range">
              <label class="range-label-right" for="customRange2">{{$t('최댓값')}}</label>
              <input id="customRange2" v-model="sliderValue2" class="form-range" max="200"
                min="0" type="range">
              <div class="track">
                <div class="range"></div>
                <div class="thumb left"></div>
                <div class="thumb right"></div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button class="btn" style="margin-right:18px;" type="button" @click="close()">{{$t('취소')}}</button>
            <button class="btn btn-insert" style="margin-right:24px;" type="button" @click="transferMat();" :disabled="disabled">{{$t('삽입')}}</button>
          </div>
        </div>
      </div>
      <Slice v-if="showSlice" @close="showSlice=false" @crop="sliceImg()"></Slice>
    </div>

  </transition>
</template>

<script>
import { Editor } from "../js/Editor.js";
import cv from 'opencv.js';
import Slice from "./ImageSlice.vue"

let srcWidth = 0;
let srcHeight = 0;
let cropped_img = null;
let pixels = null;
const DOT_COUNT = 4;
let outputMat = [];

export default {
  components:{Slice},
  data() {
    return {
      editor: null,
      watch: false,
      sliderValue1: 80,
      sliderValue2: 120,
      checker: false,
      disabled: true,
      showImgEdge: false,
      showSlice: false,
    }
  },
  methods:
      {
        open() {
          this.showImgEdge = true;
          this.disabled = true;
          this.checker = false;
          this.sliderValue1 = 80;
          this.sliderValue2 = 120;
          const thumbLeft = document.querySelector(".thumb.left");
          const thumbRight = document.querySelector(".thumb.right");
          const range = document.querySelector(".range");

          thumbLeft.style = "";
          thumbRight.style = "";
          range.style = "";

          document.addEventListener('keydown', this.handleKeyDown);
        },
        removeHandelKeyDown() {
          document.removeEventListener('keydown', this.handleKeyDown);
        },
        handleKeyDown(event) {
          if (event.key === "Escape") {
            this.close();
          }
        },
        close() {
          this.removeHandelKeyDown();
          this.editor.srcImg = null;
          this.showImgEdge = false;
        },
        loadImg() {
          this.$refs.img.src = this.editor.srcImg;
          // this.editor.img = this.editor.srcImg; // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

          this.drawImg();
        },
        sliceImg(){
          this.$refs.img.src = this.editor.img;
          this.drawImg();
        },
        drawImg() {
          let canvas = this.$refs.canvas;
          let tar = this.$refs.dstimg;
          let ctx = canvas.getContext('2d');
          let img = this.$refs.img;

          img.onload = function () {
            srcWidth = img.width;
            srcHeight = img.height;

            canvas.width = 300;
            canvas.height = 200;
            tar.width = 300;
            tar.height = 200;

            ctx.drawImage(img, 0, 0, 300, 200);

            //  this.editor.img = canvas.toDataURL();
            };

            this.watch = true;
        },
        makeEdge() {
          if (this.checker == false) {
            let src = cv.imread(this.$refs.img);
            let dst = new cv.Mat();
            let dst_resize = new cv.Mat();
            let dst_filter = new cv.Mat();
            let rect = this.ResampleAndCrop(src, srcWidth, srcHeight);
            dst = src.roi(rect);
            let dsize = new cv.Size(300, 200);
            cv.resize(dst, dst_resize, dsize, 0, 0, cv.INTER_AREA);
            //Resize 300, 200
            cv.imshow("canvas", dst_resize);
            cv.cvtColor(dst, dst, cv.COLOR_BGR2GRAY, 0);
            cv.Canny(dst_resize, dst_filter, parseInt(this.sliderValue1), parseInt(this.sliderValue2) + 100);
            dst = dst_filter;
            cv.imshow("dstimg", dst_filter);
            this.ColorConvert();
            src.delete();
            dst.delete();
            this.checker = true;
            this.disabled = false;
          } else if (this.checker == true){
            let canvas = this.$refs.canvas;
            let src = cv.imread(canvas);
            let dst = null;
            dst = new cv.Mat();

            cv.cvtColor(src, dst, cv.COLOR_BGR2GRAY, 0);
            cv.Canny(src, dst, parseInt(this.sliderValue1), parseInt(this.sliderValue2) + 100);
            cv.imshow("dstimg", dst)
            this.ColorConvert();
            src.delete();
            dst.delete();
          }
        },
        ResampleAndCrop(srcImg, targetWidth, targetHeight) {
          var sourceAspect = targetWidth / targetHeight; // source Canvas Aspect : 1
          var targetAspect = 1.5; //target Image Aspect
          //300 200 "?" "?" 1 1.5

          if (sourceAspect > targetAspect) { //srcImg > 1.5
            this.xOffset = targetHeight * targetAspect;
            this.point = (targetWidth - this.xOffset) / 2;
            cropped_img = new cv.Rect(this.point, 0, this.xOffset, targetHeight);
            this.point = 0;
            this.yOffset = 0;
          } else { //srcImg < 1.5
            this.yOffset = (targetWidth / targetAspect);
            this.point = (targetHeight - this.yOffset) / 2;
            cropped_img = new cv.Rect(0, this.point, targetWidth, this.yOffset)
            this.point = 0;
            this.xOffset = 0;
          }

          return cropped_img;
        },
        ColorConvert() {
          let canvas = this.$refs.dstimg;
          let ctx = canvas.getContext('2d');
          pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);

          for (var i = 0; i < pixels.data.length; i += 1) {
            if ((i + 1) % 4 == 0) {
              pixels.data[i] = 255;
            } else {
              if (pixels.data[i] == 255) {
                pixels.data[i] = 0;
              } else {
                pixels.data[i] = 255;
              }
            }
          }
          ctx.putImageData(pixels, 0, 0);
        },
        transferMat() {
          this.removeHandelKeyDown();
          let count = 0;
          let _pixels = [];
          let _color = 0;
          for (let i = 0; i < pixels.data.length; i++) {
            if ((i + 1) % 4 == 0) {
              if (_color > 0) {
                _pixels.push(255);
              } else {
                _pixels.push(0);
              }
              _color = 0;
              continue;
            }
            _color += pixels.data[i];
          }

          outputMat = Array.from({length: 60 * 40}, () => false);
          if (_pixels != null) {
            for (let j = 0; j < 200; j += 5) {
              for (let i = 0; i < 300; i += 5) {
                count = 0;
                for (let c = i; c < i + 5; c++) {
                  for (let r = j; r < j + 5; r++) {
                    if (_pixels[r * 300 + c] != 255) count++;
                  }
                }
                if (count >= DOT_COUNT) {
                  outputMat[(j / 5) * 60 + (i / 5)] = true;
                }
              }
            }
            this.$emit('set-image-mat', outputMat);
          }
        },
        setRangeEvent(){
          const inputLeft = document.getElementById("customRange1");
          const inputRight = document.getElementById("customRange2");

          const thumbLeft = document.querySelector(".thumb.left");
          const thumbRight = document.querySelector(".thumb.right");

          const range = document.querySelector(".range");

          const setLeftValue = e => {
            const _this = e.target;
            const { value, min, max } = _this;

            if (+inputRight.value - +value < 10) {
              _this.value = +inputRight.value - 10;
            }

            const percent = ((+_this.value - +min) / (+max - +min)) * 100;

            thumbLeft.style.left = `${percent}%`;
            range.style.left = `${percent}%`;
          };

          const setRightValue = e => {
            const _this = e.target;
            const { value, min, max } = _this;

            if (+value - +inputLeft.value < 10) {
              _this.value = +inputLeft.value + 10;
            }

            const percent = ((+_this.value - +min) / (+max - +min)) * 100;

            thumbRight.style.right = `${100 - percent}%`;
            range.style.right = `${100 - percent}%`;
          };

          if (inputLeft && inputRight) {
            inputLeft.addEventListener("input", setLeftValue);
            inputRight.addEventListener("input", setRightValue);
          }
        },
        reset(){
          this.loadImg();
          this.open();
        }
      },
  watch:
      {
        sliderValue1() {
          if(parseInt(this.sliderValue1) > parseInt(this.sliderValue2) - 10){
            this.sliderValue1 = parseInt(this.sliderValue2) - 10;
          }

          if(this.checker == true)
          {
            this.makeEdge();
          }
        },
        sliderValue2() {
          if(parseInt(this.sliderValue1) + 10 > parseInt(this.sliderValue2)){
            this.sliderValue2 = parseInt(this.sliderValue1) + 10;
          }

          if(this.checker == true)
          {
            this.makeEdge();
          }
        },
        showSlice: function () {
          let canvas = this.$refs.canvas;
          this.editor.img = canvas.toDataURL();
        }
      },
  mounted() {
    this.editor = new Editor;
    //this.loadImg();

    this.setRangeEvent();
  },
}
</script>

<style scoped>
.div-image {
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.imgcanvas {
  width: 400px;
  height: 300px;
  /* border: 2px solid #000; */
  background: #EAEAEA;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  display: table;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  width: 1100px;
  background-color: white;
  position: absolute;
  top: 66px;
  padding: 50px 20px 50px 20px;
  border-radius: 40px;
}

.modal-body {
  display: flex;
  justify-content: center;
  margin-bottom: 4vh;
}

.div-action {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

label {
  font-size: 1.2em;
}

.btn {
  width: 160px;
  height: 64px;
  background: #AAABAB;
  border-radius: 60px;
  font-size: 24px;
  color: #FFFFFF;
}

.btn-insert {
  background-color: #EA5414;
}

.slider {
  width: 100%;
  height: 100%;
  /* 후에 padding: 1.5rem 2rem 으로 수정합니다. 참고바랍니다. */
  padding: 1.5rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

input {
  width: calc(100% - 2rem);
  top: 1rem;
  left: 1rem;
  position: absolute;
  border: none;
  pointer-events: none;
  z-index: 10;
  appearance: none;
  opacity: 0;
}

input::-webkit-slider-thumb {
  pointer-events: all;
  /* appearance, background-color는 지워도 됨 */
  appearance: none;
  background-color: red;
  width: 2.5rem;
  height: 1.5rem;
}

.track {
  position: relative;
  width: 100%;
  height: 0.5rem;
  background-color: #bdc3c7;
  border-radius: 0.5rem;
}

.range {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40%;
  right: 40%;
  background-color: var(--dot-orange);
  border-radius: 0.5rem;
}

.thumb {
  position: absolute;
  top: 0;
  transform: translateY(-0.25rem);
  width: 1rem;
  height: 1rem;
  background-color: var(--dot-orange);
  border-radius: 50%;
}

.left {
  left: 40%;
}

.right {
  right: 40%;
}

.div-range {
  width: 100%;
}

.range-label-left {
  position: absolute;
  left: 0px;
  top: -19px;
  padding-left: 1.5rem
}

.range-label-right {
  position: absolute;
  right: 0px;
  top: -19px;
  padding-right: 1.5rem
}

.w-64 {
  width: 64px;
}

.btn-slice {
  position: absolute;
  left: 350px;
  top: 10px;
}
</style>
