<template>
  <div class="modal-mask">
    <div class="modal-container position-absolute top-50 start-50 translate-middle border border-dark p-1 bg-white">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-5 bg-white" style="border-radius: 40px;">
            <div class="content">
              <section class="cropper-area">
                <div class="img-cropper" style="width:850px; height:400px;">
                  <vue-cropper ref="cropper" :aspect-ratio="3 / 2" :src="imgSrc" preview=".preview"
                    style="width:850px; height:400px;" />
                </div>
                <div class="actions">
                  <button class="btn" @click.prevent="reset" style="display:none">
                    Reset
                  </button>
                  <button class="btn" @click.prevent="$emit('close')">
                    Close
                  </button>
                  <button class="btn btn-crop" @click.prevent="cropImage">
                    Crop
                  </button>
                </div>
              </section>
              <section class="preview-area mx-3" style="display:none">
                <p>Preview</p>
                <div class="preview" style="width:300px; height:200px;"></div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { Editor } from "../js/Editor.js";

export default {
  components: {
    VueCropper,
  },
  mounted() {
    this.editor = new Editor;

    this.imgSrc = this.editor.img
    console.log(this.editor.img);
    this.$refs.cropper.replace(this.editor.img);
  },
  data() {
    return {
      imgSrc: 'http://127.0.0.1:9140/db523c57-a65b-4ef5-967f-a3808a28c632',
      cropImg: '',
      editor: null,

    };
  },
  methods: {
    image() {
      console.log(this.imgSrc)
      if(this.editor.img == null)
      {
        console.log("null")
      }
      this.imgSrc = this.editor.img
      console.log(this.imgSrc, "======",this.editor.img)
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.editor.img = this.cropImg;
      this.$emit('close')
      this.$emit('crop');
    },
    reset() {
      this.$refs.cropper.reset();
    },
  },
};
</script>

<style>
.cropper-container {
  border-radius: var(--bs-border-radius-2xl) !important;
  overflow: hidden;
  background: #F7F7F7;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
  width: 1024px;
  margin: 0 auto;
}

input[type="file"] {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 850px;
}

.actions {
  margin-top: 2.5rem;
  text-align: right;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}

.modal-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-container {
  width: 950px;
  height: 600px;
  text-align: center;
  background: #F7F7F7;
  border-radius: 40px;
}

.btn {
  width: 160px;
  height: 64px;
  background: #AAABAB;
  border-radius: 60px;
  font-size: 24px;
  color: #FFFFFF;
}

.btn-crop {
  margin-left:18px;
  background-color: #EA5414;
}
</style>
