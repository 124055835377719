let Editor_Instance = null;
class Editor {
    constructor() {
        if (Editor_Instance) return Editor_Instance;
        this.srcImg = null;
        this.img = null;
        Editor_Instance = this;
        this.srcWidth = 0;
        this.srcHeight = 0;
    }
}

export {
    Editor
}
